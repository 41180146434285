@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.article-card {
  $block-name: &;

  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  min-height: 450px;
  font-size: 0;
  line-height: 0;

  &:hover {
    #{$block-name} {
      &__title {
        color: $color-main;
      }
    }
  }

  &:active {
    #{$block-name} {
      &__title {
        color: $black-200;
      }
    }
  }

  &__author-wrap {
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;
    z-index: 1;

    a {
      transition: color $transition-time;

      &:hover {
        color: $green-500;
      }

      &:active {
        color: $color-main;
      }
    }
  }

  &__tag {
    /* stylelint-disable-next-line no-descending-specificity */
    a,
    span {
      display: inline-flex;
      padding: 4px 8px;
      text-decoration: none;
      color: $black-400;
      font-family: $font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    a {
      transition: color $transition-time;

      &:hover {
        color: $color-main;
      }

      &:active {
        color: $black-400;
      }
    }
  }

  &__author {
    display: inline-flex;
    margin: -5px;
    padding: 5px;
    color: $color-main;
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-decoration: none;
  }

  &__bottom-box {
    margin-top: auto;
    position: relative;
    z-index: 1;
  }

  &__date,
  &__reading {
    color: $dark-gray-200;
    font-family: $font-family;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
  }

  &__date-reading {
    font-size: 0;
    line-height: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
  }

  &__image-box {
    overflow: hidden;
    border-radius: $border-radius-s;
  }

  &__reading {
    position: relative;
    padding-left: 20px;

    &::before {
      @include size(4px);

      content: '';
      display: block;
      position: absolute;
      top: 50%;
      background-color: #7c808b;
      border-radius: 50%;
      margin-top: -2px;
      left: 9px;
    }
  }

  &__tags {
    @include unlist;

    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    margin: -4px -8px;
    font-size: 0;
    line-height: 0;
  }

  &__text-box {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 14px 12px;
  }

  &__title {
    color: $black-200;
    min-height: 48px;
    transition: color $transition-time;

    .title {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &__link {
    @include position(absolute, 0 0 0 0);
  }

  //   #{$block-name} {
  //   }
}
